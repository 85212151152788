import React from "react";
import PressListItem from "./PressListItem";
import style from "./News.module.css";

const PressList = ({data}) => {

    return  (
        <section className="uk-section">
            <div className="uk-container uk-container-small">
                <div className="uk-grid" data-uk-grid>
                    <div className="post-list uk-width-1-1">
                        {data.slice(1).map(post =>
                            <PressListItem press={post.node} key={post.node.slug} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PressList;
