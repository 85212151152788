import React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import Header from "../components/Header"
import PressList from '../components/News/PressList';
import SEO from "../components/seo"
import style from "../components/News/News.module.css";

const Press = ({data, ...props}) => {
    let pressData = data.allContentfulNewsPost.edges;
    pressData.sort((a, b) => new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime());
    const title = `In the News`

    return (
        <Layout>
            <SEO title={title} />
            <Helmet>
            </Helmet>
            <Header data={pressData[0].node} link={`/news/press/${pressData[0].node.slug}`} />
            <section className="uk-section">
                <div style={{minHeight: "100vh"}}>
                    <div className={`${style.postList}`}><PressList data={pressData} /></div>
                </div>
            </section>

        </Layout>
    )
}

export default Press;

export const pressPageQuery = graphql`
    query pressPageQuery {
      allContentfulNewsPost {
        edges {
          node {
            title
            publishDate
            byLine
            copy {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slug
          }
        }
      }
    }`
